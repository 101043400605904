import "core-js/stable";
import "regenerator-runtime/runtime";

import "./js/config.js";

// Load in vendor scripts (jquery loaded by Webpack directly);
import "bootstrap";

// Lazysizes
import 'lazysizes';
import 'lazysizes/plugins/native-loading/ls.native-loading';

// Load in styles
import "./scss/styles.scss";

import validationService from './js/validationService';
import addressFinder from './js/addressFinder';
import { da } from "date-fns/locale";

validationService();

// todo : move this to load if needed only
const addressFinders = document.querySelectorAll('.js-find-address');

if (addressFinders.length) {
    addressFinders.forEach((x) => {
        addressFinder(x);
    })
}

// Conditional dynamic imports

// Webpack will store those modules in separate JS files and
// load them on demand, keeping payload down.


// Load in custom JS modules required on initial page load

import('./js/viewport-size');

if (document.querySelector('body.site-layout') != null) {
    import('./js/scrolling');
    import('./js/keyboard-accessibility-check');
}

if (document.querySelector('.header--content--menu') != null) {
    import('./js/main-navigation');
}

if (document.querySelector('#header--search-form') != null) {
    import('./js/header-search');
}

if (document.querySelector('#checkout-time-remaining') != null) {
    import('./js/checkout-time-remaining');
}

if (document.querySelector('.emergency-message') != null) {
    import('./js/alert-message');
}

if (document.querySelector('[name="donation-amount"]') != null) {
    import('./js/donations');
}

if (document.querySelector('#home-education-booking-form') != null) {
    import('./js/home-education-booking-form.js');
}

if (document.querySelector('#sc-map') != null) {
    import('./modules/interactive-map/interactive-map.js');
}

// Load in custom JS modules on initial page load and when DOM is updated

const addDataJsParsed = (elements, tag = '') => {
    elements.forEach(x => {
        x.setAttribute(`data-js-parsed--${tag}`, '');
    });
}

const jsModuleCheck = async () => {

    let testQuery = null;

    // defer-iframe-content.js
    testQuery = document.querySelector('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('iframe.defer-iframe-content[data-src]:not([data-js-parsed--defer-iframe-content])');
        addDataJsParsed(thisDomQuery, "defer-iframe-content");
        import('./js/defer-iframe-content').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hunt-watch
    testQuery = document.querySelector('.hunt-watch:not([data-js-parsed--hunt-watch])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hunt-watch:not([data-js-parsed--hunt-watch])');
        addDataJsParsed(thisDomQuery, "hunt-watch");
        import('./js/hunt-watch').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // better-than-native bootstrap carousel swipe detection
    testQuery = document.querySelector('.carousel:not([data-js-parsed--bs-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.carousel:not([data-js-parsed--bs-carousel])');
        addDataJsParsed(thisDomQuery, "bs-carousel");
        import('./js/bootstrap-carousel-swipe').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // image-loader-group
    testQuery = document.querySelector('.image-loader-group:not([data-js-parsed--image-loader-group])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.image-loader-group:not([data-js-parsed--image-loader-group])');
        addDataJsParsed(thisDomQuery, "image-loader-group");
        import('./js/image-loader-group').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - vimeo
    testQuery = document.querySelector('.hero--video.vimeo:not([data-js-parsed--vimeo])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.vimeo:not([data-js-parsed--vimeo])');
        addDataJsParsed(thisDomQuery, "vimeo");
        import('./js/hero-video-vimeo').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // hero video - youtube
    testQuery = document.querySelector('.hero--video.youtube:not([data-js-parsed--youtube])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.hero--video.youtube:not([data-js-parsed--youtube])');
        addDataJsParsed(thisDomQuery, "youtube");
        import('./js/hero-video-youtube').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // list-carousel
    testQuery = document.querySelector('.list-carousel:not([data-js-parsed--list-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.list-carousel:not([data-js-parsed--list-carousel])');
        addDataJsParsed(thisDomQuery, "list-carousel");
        import('./js/list-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // non-lazyload img-load-watcher
    testQuery = document.querySelector('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('img.img-load-watcher:not([data-js-parsed--img-load-watcher])');
        addDataJsParsed(thisDomQuery, "img-load-watcher");
        import('./js/img-load-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // height matching
    testQuery = document.querySelector('*[data-hm-group]:not([data-js-parsed--height-match])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-hm-group]:not([data-js-parsed--height-match])');
        addDataJsParsed(thisDomQuery, "height-match");
        import('./js/height-match').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // collapse - expand for desktop
    testQuery = document.querySelector('.expand-for-desktop:not([data-js-parsed--expand-for-desktop])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.expand-for-desktop:not([data-js-parsed--expand-for-desktop])');
        addDataJsParsed(thisDomQuery, "expand-for-desktop");
        import('./js/collapse-expand-for-desktop').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // lightboxes (PhotoSwipe v5 - galleries and single images)
    testQuery = document.querySelector('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.lightbox-image:not([data-js-parsed--lightbox]), .image-gallery:not([data-js-parsed--lightbox])');
        addDataJsParsed(thisDomQuery, "lightbox");
        import('./modules/lightboxes-galleries/lightboxes.js').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // thumbnail gallery
    testQuery = document.querySelector('.thumbnail-gallery:not([data-js-parsed--thumbnail-gallery])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.thumbnail-gallery:not([data-js-parsed--thumbnail-gallery])');
        addDataJsParsed(thisDomQuery, "thumbnail-gallery");
        import('./modules/thumbnail-gallery/thumbnail-gallery').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // img-scale-wrapper--collection-item (collection-img-scale)
    testQuery = document.querySelector('.img-scale-wrapper--collection-item:not([data-js-parsed--collection-img-scale])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.img-scale-wrapper--collection-item:not([data-js-parsed--collection-img-scale])');
        addDataJsParsed(thisDomQuery, "collection-img-scale");
        import('./js/collection-img-scale').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // data-sizes-js-fix (fixes lazysizes issue)
    testQuery = document.querySelector('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-sizes-js-fix]:not([data-js-parsed--data-sizes-js-fix])');
        addDataJsParsed(thisDomQuery, "data-sizes-js-fix");
        import('./js/data-sizes-js-fix').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // testimonials-carousel
    testQuery = document.querySelector('.testimonials-carousel:not([data-js-parsed--testimonials-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.testimonials-carousel:not([data-js-parsed--testimonials-carousel])');
        addDataJsParsed(thisDomQuery, "testimonials-carousel");
        import('./modules/testimonials-carousel/testimonials-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // awards-carousel
    testQuery = document.querySelector('.awards-carousel:not([data-js-parsed--awards-carousel])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.awards-carousel:not([data-js-parsed--awards-carousel])');
        addDataJsParsed(thisDomQuery, "awards-carousel");
        import('./modules/awards-carousel/awards-carousel').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // opening times calendar
    testQuery = document.querySelector('.opening-times:not([data-js-parsed--calendar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.opening-times:not([data-js-parsed--calendar])');
        addDataJsParsed(thisDomQuery, "calendar");
        import('./modules/opening-times-calendar/opening-times-calendar').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive nav bar
    testQuery = document.querySelector('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-nav-bar:not([data-js-parsed--responsive-nav-bar])');
        addDataJsParsed(thisDomQuery, "responsive-nav-bar");
        import('./modules/responsive-nav-bar/responsive-nav-bar').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // responsive table
    testQuery = document.querySelector('.responsive-table:not([data-js-parsed--responsive-table])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.responsive-table:not([data-js-parsed--responsive-table])');
        addDataJsParsed(thisDomQuery, "responsive-table");
        import('./modules/responsive-table/responsive-table');
    }

    // timeline
    testQuery = document.querySelector('.timeline:not([data-js-parsed--timeline])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.timeline:not([data-js-parsed--timeline])');
        addDataJsParsed(thisDomQuery, "timeline");
        import('./modules/timeline/timeline');
    }

    // tripadvisor icon shiv
    if (document.querySelector('.fa-tripadvisor') != null) {
        import('./js/tripadvisor-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tripadvisor'));
        });
    }

    // tiktok icon shiv
    if (document.querySelector('.fa-tiktok') != null) {
        import('./js/tiktok-icon-shiv').then((js) => {
            js.createFrom(document.querySelectorAll('.fa-tiktok'));
        });
    }

    // smart-bootstrap-tabs
    testQuery = document.querySelector('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.nav[role="tablist"]:not([data-js-parsed--smart-bootstrap-tabs])');
        addDataJsParsed(thisDomQuery, "smart-bootstrap-tabs");
        import('./js/smart-bootstrap-tabs').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // help search form
    testQuery = document.querySelector('.help-search-form:not([data-js-parsed--help-search-form])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.help-search-form:not([data-js-parsed--help-search-form])');
        addDataJsParsed(thisDomQuery, "help-search-form");
        import('./js/help-search-form').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // collections search form
    testQuery = document.querySelector('.collections-search-form:not([data-js-parsed--collections-search-form])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.collections-search-form:not([data-js-parsed--collections-search-form])');
        addDataJsParsed(thisDomQuery, "collections-search-form");
        import('./js/collections-search-form').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // filter-options-- date-dropdown
    testQuery = document.querySelector('.filter-options--date-dropdown:not([data-js-parsed--filter-options-date-dropdown])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.filter-options--date-dropdown:not([data-js-parsed--filter-options-date-dropdown])');
        addDataJsParsed(thisDomQuery, "filter-options-date-dropdown")
        import('./js/filter-options-date-dropdown').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // form number control
    testQuery = document.querySelector('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.form-number-control:not(.readonly):not([data-js-parsed--form-number-control])');
        addDataJsParsed(thisDomQuery, "form-number-control");
        import('./js/form-number-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // form-check enable target
    testQuery = document.querySelector('input[data-form-check-enable-target]:not([data-js-parsed--form-check-enable-target])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('input[data-form-check-enable-target]:not([data-js-parsed--form-check-enable-target])');
        addDataJsParsed(thisDomQuery, "form-check-enable-target");
        import('./js/form-check-enable-target').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // radio-collapse-control
    testQuery = document.querySelector('.radio-collapse-control:not([data-js-parsed--radio-collapse-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.radio-collapse-control:not([data-js-parsed--radio-collapse-control])');
        addDataJsParsed(thisDomQuery, "radio-collapse-control");
        import('./js/radio-collapse-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // checkbox-collapse-control
    testQuery = document.querySelector('.checkbox-collapse-control:not([data-js-parsed--checkbox-collapse-control])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.checkbox-collapse-control:not([data-js-parsed--checkbox-collapse-control])');
        addDataJsParsed(thisDomQuery, "checkbox-collapse-control");
        import('./js/checkbox-collapse-control').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // width-watcher
    testQuery = document.querySelector('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('*[data-width-watcher]:not([data-js-parsed--width-watcher])');
        addDataJsParsed(thisDomQuery, "width-watcher");
        import('./js/width-watcher').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // scroll indicator
    testQuery = document.querySelector('.scroll-indicator:not([data-js-parsed--scroll-indicator])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.scroll-indicator:not([data-js-parsed--scroll-indicator])');
        addDataJsParsed(thisDomQuery, "scroll-indicator");
        import('./js/scroll-indicator').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // voucher-delivery-date
    testQuery = document.querySelector('input[data-voucher-delivery-date]:not([data-js-parsed--voucher-delivery-date])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('input[data-voucher-delivery-date]:not([data-js-parsed--voucher-delivery-date])');
        addDataJsParsed(thisDomQuery, "voucher-delivery-date");
        import('./js/voucher-delivery-date').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

    // checkout extras (modals)
    testQuery = document.querySelector('.checkout--extras-listing--item[data-extras-product-id]:not([data-js-parsed--checkout-extras])');
    if (testQuery != null) {
        let thisDomQuery = document.querySelectorAll('.checkout--extras-listing--item[data-extras-product-id]:not([data-js-parsed--checkout-extras])');
        addDataJsParsed(thisDomQuery, "checkout-extras");
        import('./js/checkout-extras').then((js) => {
            js.createFrom(thisDomQuery);
        });
    }

};


const calendarBookingElements = document.querySelectorAll('.js-calendar-booking');

if (calendarBookingElements.length) {
    import('./js/calendarBookingApi').then(({ default: CalendarBookingApi }) => {
        calendarBookingElements.forEach(calendarBookingElement => {
            new CalendarBookingApi(calendarBookingElement);
        });
    });
}

const giftVoucherForm = document.getElementById('gift-voucher-form');

if (giftVoucherForm) {
    import('./js/giftVouchers').then(({ default: giftVouchers }) => {
        giftVouchers(giftVoucherForm);
    });
}

//// create a mutation observer to watch for dynamically added HTML

let checkRequested = false, checkTimeout = null;

// run a check on page load
jsModuleCheck();

// Select the node that will be observed for mutations
const targetNode = document.querySelector('main');
if (targetNode != null) {
    // Options for the observer (which mutations to observe)
    const config = { attributes: false, childList: true, subtree: true };
    // Callback function to execute when mutations are observed
    const callback = function (mutationsList) {
        // Use traditional 'for loops' for IE 11
        let checkNeeded = false;
        for (const mutation of mutationsList) {
            if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                checkNeeded = true;
            }
        }
        if (checkNeeded && !checkRequested) {
            checkRequested = true;
            clearTimeout(checkTimeout);
            checkTimeout = setTimeout(() => {
                jsModuleCheck();
                checkRequested = false;
            }, 500);
        }
    };
    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
}

const payButton = document.querySelector('.js-pay-button');

if (payButton) {
    payButton.addEventListener('click', (e) => {
        e.preventDefault();

        const paymentForm = document.querySelector('[data-vendr-payment-form]');

        if (paymentForm) {
            paymentForm.submit();
        }
    });
}

const orderProcessing = document.querySelector('.js-order-processing');

if (orderProcessing) {
    setInterval(() => {
        fetch(`/Umbraco/Surface/OrderStatus/Get/?id=${orderProcessing.dataset.id}`, {
            method: 'GET'
        })
            .then(response => response.json())
            .then(response => {
                if (!response.processing) {
                    window.location = response.url
                }
                else {
                    document.querySelector('.js-processing-message').innerHTML = response.message;
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, 2000);
}

const roundUpCheckbox = document.querySelector('.js-round-up');

if (roundUpCheckbox) {
    roundUpCheckbox.addEventListener('change', function (e) {
        roundUpCheckbox.disabled = true;

        const termsCheckbox = document.getElementById('checkout-form--agree-terms');
        const payButton = document.getElementById('checkout--payment--place-order');

        if (termsCheckbox) {
            termsCheckbox.disabled = true;
        }

        if (payButton) {
            payButton.disabled = true;
        }

        e.currentTarget.closest('form').submit();
        // fetch(`/Umbraco/Surface/Order/RoundUp/?nodeId=${roundUpCheckbox.dataset.nodeId}&id=${roundUpCheckbox.dataset.id}&round=${roundUpCheckbox.checked}`, {
        //     method: 'POST'
        // })
        //     .then(response => response.json())
        //     .then(response => {
        //         const orderLinesContainer = document.querySelector('.js-round-up-order-lines');
        //         const price = document.querySelector('.js-round-up-order-total');
        //         const reviewFormContainer = document.querySelector('.js-review-form');

        //         if (orderLinesContainer) {
        //             orderLinesContainer.innerHTML = response.orderLines;
        //         }

        //         if (reviewFormContainer) {
        //             reviewFormContainer.innerHTML = response.reviewForm;
        //         }

        //         if (price) {
        //             price.innerHTML = response.value.withTax;
        //         }

        //         roundUpCheckbox.disabled = false;
        //     }).catch((error) => {
        //         roundUpCheckbox.disabled = false;
        //         console.error('Error:', error);
        //     });
    });
}

const applyDiscountButton = document.querySelector('.js-apply-voucher');
const discountCodeInput = document.getElementById('DiscountGiftCardCode');

if (applyDiscountButton && discountCodeInput) {
    applyDiscountButton.addEventListener('click', function () {
        if (discountCodeInput.value) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'apply_voucher',
                coupon: discountCodeInput.value
            });
        }
    });
}

const basketButton = document.querySelector('.js-basket-button');

if (basketButton && basketDatalayer) {
    basketButton.addEventListener('click', function () {

        var beginCheckout = { ...basketDatalayer };

        beginCheckout.event = 'begin_checkout';

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(beginCheckout);
    });
}

const basketDonate = document.querySelector('.js-basket-donate');
const amountInput = document.getElementById('Amount');

if (basketDonate && amountInput) {
    basketDonate.addEventListener('click', function (e) {
        const value = parseFloat(amountInput.value);

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'add_to_cart',
            funnel_step: 'cart_donation',
            currency: 'GBP',
            value: value,
            items: [
                {
                    item_id: basketDonate.dataset.sku,
                    item_name: basketDonate.dataset.name,
                    currency: 'GBP',
                    index: 0,
                    item_category: basketDonate.dataset.department,
                    price: value,
                    quantity: 1
                },
            ]
        });

        e.preventDefault();
    });
}

const funnelButtons = document.querySelectorAll('.js-funnel-button');

if (funnelButtons.length) {
    funnelButtons.forEach((button) => {
        button.addEventListener('click', function (e) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                event: 'funnel_selection',
                funnel: e.currentTarget.dataset.funnel
            });
        });
    });
}

const guestCustomerInformationButton = document.querySelector('.js-guest-customer-information-button');

if (guestCustomerInformationButton) {
    guestCustomerInformationButton.addEventListener('click', function (e) {
        const addressLine1 = document.getElementById('BillingAddress_AddressLine1');
        const city = document.getElementById('BillingAddress_City');
        const county = document.getElementById('BillingAddress_County');
        const postcode = document.getElementById('BillingAddress_Postcode');
        const country = document.getElementById('BillingAddress_Country');

        let shouldStop = addressLine1.value === '' || city.value === '' || county.value === '' || postcode.value === '' || country.value === '';

        if (shouldStop) {
            const billingAddressCollapseButton = document.querySelector('.js-billing-address-button');

            if (billingAddressCollapseButton.getAttribute('aria-expanded') === 'false') {
                var clickEvent = new MouseEvent("click", {
                    "view": window,
                    "bubbles": true,
                    "cancelable": false
                });

                billingAddressCollapseButton.dispatchEvent(clickEvent);

                e.preventDefault();

                window.validationService.validateForm(e.currentTarget.closest('form'));
            }
        }
    });
}